import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import styled from 'styled-components';
import FormDevis from '../components/FormDevis';
import Testimonials from '../components/Testimonials';
import Zones from '../components/Zones';
import Prestations from '../components/Prestations';

import MainCta from '../components/MainCta';

import FaqSection from '../components/FaqSection';
import Breadcrumb from '../components/Breadcrumb';
import BlogSection from '../components/BlogSection';
import Layout from '../components/Layout';

export const query = graphql`
  query Lausanne_Images {
    teamImage: file(
      relativePath: { eq: "serrurier-lausanne/eco-responsable.jpeg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    bikeImage: file(relativePath: { eq: "bike.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    deplacementGratuitImage: file(
      relativePath: { eq: "deplacement-gratuit.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    genevaMap: file(relativePath: { eq: "serrurier-lausanne/qualified.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    googleImage: file(relativePath: { eq: "google-image.webp" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const LausanneFaq = [
  {
    id: 1,
    question:
      "Quel est le prix à payer pour la duplication d'une clé à Lausanne ?",
    answer:
      "Le prix à payer pour obtenir le double d'une clé dépend généralement de plusieurs facteurs, à savoir la marque et le type de serrure, mais également de la société de serrurerie à laquelle vous faites appel",
  },
  {
    id: 2,
    question: 'Quelle est la solution pour ma serrure bloquée ?',
    answer:
      'Les solutions possibles pour une serrure bloquée dépendent de la raison de ce blocage. Il faudra retirer un bout de clé et débloquer un cylindre (HS) par un serrurier qui saura vous expliquer la cause du problème.<br/><br/>Vous pouvez solliciter <strong>nos serruriers à Lausanne</strong> pour vous venir en aide .',
  },
  {
    id: 3,
    question: 'Est-il nécessaire de prévoir un double de clé ?',
    answer:
      "En cas de perte ou de vol, il est préférable de posséder une deuxième clé. S'il s'agit d'un bien locatif, demandez la permission au bailleur pour dupliquer la clé.<br/>Rappelons que chez Super Serrurier, nous confectionnons tout type de clés. Faîtes appel à nous !",
  },
  {
    id: 4,
    question: 'Quels sont les tarifs pratiqués par les serruriers à Lausanne ?',
    answer:
      "Les prix d'un dépannage de serrurerie à Lausanne sont déterminés selon les prestations demandées.<br/>N'hésitez pas à nous joindre si vous souhaitez vous renseigner sur les coûts des différentes prestations de serrurerie à Lausanne.",
  },
];

const pages = [
  { name: 'Canton de vaud', href: '/vaud', current: false },
  { name: 'Lausanne', href: '/serrurier-lausanne/', current: true },
];

export default function Geneve({ data }) {
  const { deplacementGratuitImage, teamImage, genevaMap, googleImage } = data;
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Serrurier lausanne, serrurier d’urgence 24/7 - Super serrurier
        </title>
        <meta
          name="description"
          content="Intervention rapide. Super serrurier d’urgence Lausanne est disponible tous les jours de la semaine à n’importe quelle heure pour tous vos besoins en serrurerie."
        />
      </Helmet>
      <div className="relative bg-gray-100 overflow-hidden">
        <MainTownHeader
          className="relative  bg-gray-800 pt-6 pb-16 sm:pb-24 py-16"
          id="main-jumbo"
        >
          <main className="mt-24 mx-auto max-w-7xl px-4 sm:mt-10">
            <div className="text-center">
              <div className="flex justify-center">
                <h1 className="text-4xl tracking-tight font-extrabold text-primarycolor sm:text-5xl md:text-6xl ml-4 sm:text-white">
                  Serrurier Lausanne
                </h1>
              </div>

              {/* 
            <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
              <span className="block xl:inline">Serrurier</span>
              <span className="block text-white xl:inline">Vaud</span>
            </h1>
              */}
              <h2 className="text-lg lg:text-2xl xl:text-3xl md:block mt-3 text-gray-700 sm:text-white">
                Profitez d’un service de qualité et sans mauvaises surprises
              </h2>
              <p className="text-sm lg:text-lg text-gray-700 sm:text-white mt-4">
                Pour toute intervention rapide à Lausanne et demande de devis,
                vous pouvez nous contacter sur le numéro ci-dessous
              </p>
              {/* 
            <p className="max-w-md mx-auto text-base text-white sm:text-lg md:text-xl md:max-w-3xl">
              Profitez d’un service de qualité et sans mauvaises surprises
            </p>
            */}
            </div>

            <div className="flex justify-center">
              <a
                href="tel:0791304768"
                className="mt-4 inline-flex items-center text-secondarycolor bg-gray-200 rounded-full p-2 sm:mt-5 sm:text-base lg:mt-6 lg:text-sm xl:text-base hover:text-secondarycolor-light"
              >
                <span className="px-3 py-2 text-white text-lg lg:text-xl xl:text-1xl leading-5 tracking-wide bg-primarycolor rounded-full">
                  Appelez-nous
                </span>
                <span className="ml-3 text-lg lg:text-xl xl:text-1xl font-semibold leading-5">
                  079 130 47 68
                </span>
                {/* <!-- Heroicon name: chevron-right --> */}
                <svg
                  className="ml-2 w-6 h-6"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </a>
            </div>
            <Hr className="hidden md:block" />
            <MainCta />
          </main>
        </MainTownHeader>
        <Breadcrumb pages={pages} />
        <div className="ml-2 md:py-16 bg-white overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative lg:grid lg:grid-cols-2 lg:gap-28 lg:items-center">
              <div className="relative py-12">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Serrurier Lausanne: fiabilité et prix compétitifs
                  </h3>
                  {/*
                <!-- <h2 className="inline">
                - Faites vous dépanner par les meilleurs serruriers de votre ville
                </h2> --> */}
                </div>
                <p className="mt-6 text-lg text-gray-600">
                  Depuis le lancement de notre entreprise en 1998, nous nous
                  sommes engagés à pratiquer des tarifs très compétitifs en
                  toute transparence. Ainsi, nos prix restent particulièrement
                  attractifs. À aucun moment, nous ne cherchons à abuser d'une
                  situation d'urgence. Notre prix couvre :
                </p>
                <div className="mt-4 space-y-4">
                  <dl class="">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex-shrink-0">
                          <svg
                            class=" h-8 w-8 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="ml-2 mt-0.5">
                        <dt class="text-lg leading-6 text-gray-600">
                          Les frais de déplacement.
                        </dt>
                      </div>
                    </div>
                  </dl>
                  <dl class="">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex-shrink-0">
                          <svg
                            class=" h-8 w-8 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="ml-2 mt-0.5">
                        <dt class="text-lg leading-6 text-gray-600">
                          Le prix de l'intervention.
                        </dt>
                      </div>
                    </div>
                  </dl>
                  <dl class="">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <div class="flex-shrink-0">
                          <svg
                            class=" h-8 w-8 text-green-500"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M5 13l4 4L19 7"
                            ></path>
                          </svg>
                        </div>
                      </div>
                      <div class="ml-2 mt-0.5">
                        <dt class="text-lg leading-6 text-gray-600">
                          Le prix du matériel de rechange.
                        </dt>
                      </div>
                    </div>
                  </dl>
                </div>

                <p className="mt-3 text-lg text-gray-600">
                  Avec Super Serrurier, vous pouvez recevoir votre devis en
                  moins de 15 minutes. Par ailleurs, les prix qui vous sont
                  communiqués sont fixes et définitifs et demeurent inchangés
                  après que vous le serrurier se soit rendu chez vous.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Nous vous invitons à prendre contact avec nous pour nous
                  soumettre vos demandes. Nous vous faisons parvenir, sans
                  tarder, un devis gratuit, et sans engagement.
                </p>
                <div className="mt-8 sm:flex">
                  <div className="block md:hidden rounded-md shadow">
                    <a
                      href="tel:0791304768"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                    >
                      Appelez-nous
                    </a>
                  </div>
                  <div className="hidden md:block mt-3 sm:mt-0 lg:ml-0">
                    <a
                      href="/contact"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white shadow-sm bg-secondarycolor hover:bg-secondarycolor-light"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 hidden lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <div className="bg-white p-8 shadow-lg rounded-lg z-10">
                  <FormDevis />
                </div>
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h3 className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                    <span className="text-primarycolor">
                      Meilleurs serruriers de Lausanne
                    </span>
                  </h3>
                  <div className="mt-3 text-lg text-gray-600">
                    <p>
                      Depuis 1998, Super Serruriers, spécialiste dans le domaine
                      de la serrurerie, rend service à plus de 3'000 clients, et
                      ce, annuellement et obtient ainsi un taux de satisfaction
                      supérieur à 98 %.
                    </p>
                    <p className="mt-3 text-lg text-gray-600">
                      En effet, Super Serrurier a obtenu plus de 220 avis avec
                      une note de 4,9 étoiles.
                    </p>
                  </div>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                    aria-hidden="true"
                  >
                    <defs>
                      <pattern
                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="text-gray-200"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                    ></rect>
                  </svg>
                  <Image
                    className="relative mx-auto"
                    fluid={googleImage.childImageSharp.fluid}
                    fadeIn={false}
                    loading="eager"
                    alt="Meilleur serrurier de Lausanne"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                  <h3 className="inline text-primarycolor">
                    Super Serrurier : les serruriers les plus qualifiés à
                    Lausanne
                  </h3>
                  {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                </div>
                <p className="mt-3 text-lg text-gray-600">
                  Chez Super Serrurier à Lausanne, vous êtes à la bonne adresse
                  si vous souhaitez vous faire aider par des professionnels de
                  confiance. Notre entreprise regroupe les meilleurs serruriers
                  qui pourront intervenir en urgence pour un dépannage de
                  serrurerie.
                </p>
                <p className="mt-3 text-lg text-gray-600">
                  Le savoir-faire et l'expertise de nos collaborateurs sont
                  reconnus sur le terrain. De plus, nous disposons de matériel
                  de pointe et des connaissances nécessaires pour remédier à vos
                  problèmes.
                </p>

                <p className="mt-3 text-lg text-gray-600">
                  Notre mission est de mettre tout en œuvre pour que vous
                  puissiez bénéficier d'un service de qualité et surtout sans
                  endommager vos portes et serrures.
                </p>

                <p className="mt-3 text-lg text-gray-600">
                  Dans cette optique, nous mettons à votre disposition des
                  équipes certifiés, expérimentées, et fiable
                </p>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="text-gray-200"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)"
                  ></rect>
                </svg>
                <Image
                  className="relative mx-auto w-4/5"
                  fluid={genevaMap.childImageSharp.fluid}
                  fadeIn={false}
                  loading="eager"
                  alt="Déplacement gratuit et sans engagement"
                />
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>
          </div>
        </div>

        <Testimonials />

        <div className="ml-2 mt-4 md:py-16 bg-gray-50 overflow-hidden lg:py-24">
          <div
            className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8"
            style={{ maxWidth: '100rem' }}
          >
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
              ></rect>
            </svg>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="text-gray-200"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-0">
              <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <div className="relative mt-12 lg:mt-0 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                  <div className="relative">
                    <div className="text-2xl font-extrabold text-textcolor tracking-tight sm:text-3xl">
                      <h3 className="inline text-primarycolor">
                        Une entreprise éco-responsable
                      </h3>
                      {/*<!-- <h2 className="inline">- Prix 100% transparents</h2> --> */}
                    </div>
                    <p className="mt-3 text-lg text-gray-600">
                      Super serrurier, et ce, depuis 2017, a pris les devants
                      sur le plan écologique. En effet, près de 30 % de nos
                      serruriers à Lausanne effectuent leurs trajets en vélo
                      électrique. Sans compter que cela leur permet de s'y
                      rendre plus vite lors des heures de bouchons. Un avantage
                      qui met en avant notre réputation.
                    </p>
                    <div className="mt-8 sm:flex">
                      <div className="block md:hidden rounded-md shadow">
                        <a
                          href="tel:0791304768"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-secondarycolor hover:bg-secondarycolor-light lg:hidden"
                        >
                          Appelez-nous
                        </a>
                      </div>
                      <div className="hidden md:block mt-3 sm:mt-0 sm:ml-3 lg:ml-0">
                        <a
                          href="/contact"
                          className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-secondarycolor shadow-sm border-gray-200 bg-white hover:bg-gray-200"
                        >
                          Contact
                        </a>
                      </div>
                    </div>
                  </div>

                  <div
                    className="mt-10 -mx-4 relative lg:mt-0"
                    aria-hidden="true"
                  >
                    <Image
                      fluid={teamImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      style={{ borderRaduis: '7px' }}
                      className="relative mx-auto"
                      alt="Serrurier Éthique et responsable"
                    />
                  </div>
                </div>
                <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-28 lg:items-center mt-12 lg:mt-32">
                  <div className="lg:col-start-2">
                    <h2 className="text-2xl font-extrabold text-primarycolor tracking-tight sm:text-3xl">
                      Serrurier Lausanne : nous serons sur place en 20 minutes
                    </h2>
                    <div className="mt-3 text-lg text-gray-600">
                      <p>
                        Nous sommes situés dans un coin stratégique de Lausanne,
                        en un temps record vous nous trouverez sur le pas de
                        votre porte. En général, nous sommes auprès de vous en
                        moins de 20 minutes, même en cas d’urgence.
                      </p>
                      <p className="mt-3 text-lg text-gray-600">
                        Quoi qu'il en soit, rassurez-vous, après votre appel, un
                        de nos super serruriers se rend tout de suite sur place.
                      </p>
                    </div>
                  </div>
                  <div className="-mx-4 relative lg:mt-0 lg:col-start-1 mt-12">
                    <svg
                      className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                      width="784"
                      height="244"
                      fill="none"
                      viewBox="0 0 784 404"
                      aria-hidden="true"
                    >
                      <defs>
                        <pattern
                          id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                          x="0"
                          y="0"
                          width="20"
                          height="20"
                          patternUnits="userSpaceOnUse"
                        >
                          <rect
                            x="0"
                            y="0"
                            width="4"
                            height="4"
                            className="text-gray-200"
                            fill="currentColor"
                          ></rect>
                        </pattern>
                      </defs>
                      <rect
                        width="784"
                        height="404"
                        fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)"
                      ></rect>
                    </svg>
                    <Image
                      fluid={deplacementGratuitImage.childImageSharp.fluid}
                      fadeIn={false}
                      loading="eager"
                      alt="Serrurier rapide et efficace"
                      className="relative mx-auto"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Zones
          title="Nos zones d’interventions"
          text="Nous détenons un réseau de serruriers opérationnels 24h/24 et 7j/7 à longueur de l'année, y compris les jours fériés, et ce, approximativement dans l'ensemble des villes lausannoises.<br/><br/> Peu importe votre localisation, il y aura toujours un excellent serrurier à Lausanne disponible pas loin de chez vous."
        />
        <Prestations
          title="Nos services"
          description="Super Serrurier vous fait bénéficier de son savoir-faire pour une prise en charge rapide à Lausanne et sa périphérie. Notre gamme de services englobe les prestations ci-dessous."
        />
        <FaqSection content={LausanneFaq} />

        <BlogSection />
      </div>
    </Layout>
  );
}

const MainTownHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url('https://res.cloudinary.com/dxm0sdgpv/image/upload/v1640087419/super-serrurier/lausanne-min_szuzrs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 767px) {
    background-image: none;
  }
`;

const Hr = styled.hr`
    height: 1.5px;
    width: 15%;
    background: #fff;
    margin: auto;
    margin-top: 2rem;
}
`;
